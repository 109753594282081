import { Injectable } from '@angular/core';
import { interval, EMPTY, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

import { ActivityLog } from 'src/app/_shared/classes/activityLog.interface';
import { DiligentApiService } from 'src/app/_shared/services/diligent-api.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EventTrackingService {
  trackingEnabled: boolean = false;
  trackedEventsBuffer: ActivityLog[] = [];
  pushIntervalSeconds = 10; // Interval in seconds between each push
  emptyPushToPause = 2; // Number or empty push before pausing
  emptyPushCounter = 0; // Counter of empty push

  trackingSubscription: Subscription | null = null;
  constructor(private diligentService: DiligentApiService) {
    this.trackingEnabled = environment.trackingEnabled;
  }
  trackEvent(activity: string, params: object = null): void {
    if (!this.trackingEnabled) {
      return;
    }
    const activityLog: ActivityLog = {
      activity: activity,
      timestamp: Date.now(),
      details: params ? JSON.stringify(params) : undefined,
    };

    this.trackedEventsBuffer.push(activityLog);

    // Start BE api push is it was paused
    if (this.trackingSubscription === null) {
      this.startPush();
    }

    // Force push before logout
    if (activity === 'header.logout') {
      this.push();
    }
  }

  /**
   * Push tracking to the server
   */
  private push(): any {
    const trackedEvents = [...this.trackedEventsBuffer];
    this.trackedEventsBuffer = [];
    if (trackedEvents.length > 0) {
      // Push event to BE
      this.diligentService.postActivitiesLogs(trackedEvents, Date.now()).subscribe();
    } else {
      // Pause recurrent push if there isn't user activity
      this.emptyPushCounter++;
      if (this.emptyPushCounter >= this.emptyPushToPause) {
        this.pausePush();
      }
    }
    return EMPTY;
  }

  /**
   * Start recurrent push
   */
  private startPush(): void {
    this.emptyPushCounter = 0;
    this.trackingSubscription = interval(this.pushIntervalSeconds * 1000)
      .pipe(
        startWith(0),
        switchMap(() => this.push())
      )
      .subscribe();
  }

  /**
   * Pause recurrent push
   */
  private pausePush(): void {
    if (this.trackingSubscription) {
      this.trackingSubscription.unsubscribe();
      this.trackingSubscription = null;
    }
  }
}
