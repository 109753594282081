import { cloneDeep } from 'lodash';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { select, Store } from '@ngrx/store';
import { ThemePalette } from '@angular/material/core';

import { ISiteMp } from 'src/app/_shared/classes/SiteMp.interface';
import { ISiteMpDialogData } from 'src/app/_shared/classes/siteMpDialog.interface';
import { MpEnum } from 'src/app/_shared/classes/measurementpoint.interface';
import { MpParameters } from 'src/app/_shared/interface/mp-parameter';
import { DiligentApiService, GeocoderService, SitesService, TokenService } from 'src/app/_shared/services';
import { EditorImageUploadComponent } from 'src/app/_shared/editor/editor-image-upload/editor-image-upload.component';
import { NotificationsService } from 'src/app/_shared/modules/notifications/shared/notifications.service';
import { MeasurementPointsService } from 'src/app/_shared/services/measurement-points.service';
import { CustomFormValidators } from 'src/app/_shared/classes/CustomFormValidators';
import * as fromStore from 'src/app/_store/_reducers';
import { IAddressComponent } from 'src/app/_shared/classes/addressComponent.interface';
import { UserActions } from 'src/app/_store/_user/actions';
import { DeleteMemberDialogComponent } from 'src/app/_shared/components/delete-member-dialog/delete-member-dialog.component';
import { MeasurementPoint } from 'src/app/_shared/classes/MeasurementPoint';
import { DragDropZoneComponent } from 'src/app/_shared/components/drag-drop-zone/drag-drop-zone.component';
import { ReplacementStep } from 'src/app/_shared/interface/replacement-step.interface';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { UserPreferences } from 'src/app/_shared/interface/user-preferences.interface';
import { AuthService } from 'src/app/_shared/services';
import { MapDialogComponent } from 'src/app/_shared/components/site-mp-dialog/map-dialog/map-dialog.component';
import { SiteMpParametersTableComponent } from 'src/app/_shared/components/site-mp-dialog/site-mp-parameters-table/site-mp-parameters-table.component';
import { MoveToComponent } from 'src/app/_shared/components/site-mp-dialog/move-to/move-to.component';
import { MeasurementPointAccessFeature } from 'src/app/_shared/classes/features';

const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'L LTS',
  },
  display: {
    dateInput: 'L LTS',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-site-mp-dialog',
  templateUrl: './site-mp-dialog.component.html',
  styleUrls: ['./site-mp-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT }],
})
export class SiteMpDialogComponent extends MpEnum(Object) implements OnInit {
  @ViewChild('parameters', { static: false }) parametersTable: SiteMpParametersTableComponent;
  @ViewChild(MatTabGroup) tabgroup: MatTabGroup;
  @ViewChild(DragDropZoneComponent) dragDropImage: DragDropZoneComponent;
  @ViewChild(MoveToComponent) moveToTab: MoveToComponent;
  refreshMpStatus = new EventEmitter();
  siteMpForm: FormGroup;
  mpStatus: FormControl;
  accountName: string;
  siteMp: ISiteMp;
  isMpStatusEnabled = false;
  title: string;
  availableStatuses: Array<number>;
  accountId: number;
  isAdmin = false;
  isSystemAdmin = false;
  latitude: number;
  longitude: number;
  expandedEditor: boolean = false;
  viewerMode: boolean;
  commissionedDateCtrl: FormControl;
  addresses: Observable<Array<any>>;
  enableMeridian: boolean;
  userPrefs: UserPreferences;
  cityList: Observable<Array<any>>;
  tabSelectionDisabled: number[];
  hideHeader: boolean;
  hideActions: boolean;
  mp: MeasurementPoint;
  defaultEditParameterName: string;
  accessTabEnabled$: Observable<boolean>;

  imgReady: boolean = true;
  mpImgUrl: string;
  mpImgId: number = null;
  mpImgInititalUrl: string;
  hideRemoveImage: boolean = true;
  editorConfig = {
    height: 179,
    menubar: false,
    image_uploadtab: true,
    setup: (editor): void => {
      editor.on('Dirty', () => {
        this.siteMpForm.markAsDirty();
        this.changeDetection.detectChanges();
      });
    },
  };

  canModify: boolean;
  new = false;
  maxDate;
  minDate;
  timezone: string;
  step;
  color: ThemePalette = 'accent';
  private currentMpSelected: boolean;
  private statusInitial: number;

  @ViewChild('search')
  searchElementRef: ElementRef;

  /**
   * List of user access changes generated by mp-access component
   */
  accessChanges: { [key: string]: boolean } = {};

  get siteName(): AbstractControl {
    return this.siteMpForm.get('siteName');
  }

  get siteShortName(): AbstractControl {
    return this.siteMpForm.get('siteShortName');
  }

  get siteInformation(): AbstractControl {
    return this.siteMpForm.get('siteInformation');
  }

  get street1(): AbstractControl {
    return this.siteMpForm.get('street1');
  }

  get street2(): AbstractControl {
    return this.siteMpForm.get('street2');
  }

  get city(): AbstractControl {
    return this.siteMpForm.get('city');
  }

  get state(): AbstractControl {
    return this.siteMpForm.get('state');
  }

  get zipCode(): AbstractControl {
    return this.siteMpForm.get('zipCode');
  }

  get country(): AbstractControl {
    return this.siteMpForm.get('country');
  }

  get mpName(): AbstractControl {
    return this.siteMpForm.get('mpName');
  }

  get mpTypeId(): AbstractControl {
    return this.siteMpForm.get('mpTypeId');
  }

  get gps(): AbstractControl {
    return this.siteMpForm.get('gps');
  }

  availablesImagesSubject = new BehaviorSubject(null);

  availablesImagesObservable = (): Observable<any> =>
    this.availablesImagesSubject.asObservable().pipe(
      switchMap(() => this.diligentAPI.getMeasurementPointFiles(this.siteMp.measurementPoint.roomId)),
      map((items) =>
        items.map((item) => ({
          title: item.s3Key,
          value: `${this.diligentAPI.endpoint}/measurementPoint/${this.siteMp.measurementPoint.roomId}/file/${item.id}`,
        }))
      )
    );

  saveImageToServer = (formData: FormData): Observable<string> => {
    return this.diligentAPI.addMeasurementPointFile(this.siteMp.measurementPoint.roomId, formData).pipe(
      map(
        (mpFile) =>
          `${this.diligentAPI.endpoint}/measurementPoint/${this.siteMp.measurementPoint.roomId}/file/${mpFile.id}`
      ),
      tap(() => this.availablesImagesSubject.next(null))
    );
  };

  saveImageToServerResolveFileName = (blobFileName: string): Observable<string> =>
    this.dialogService
      .open(EditorImageUploadComponent, {
        data: {
          blobFileName,
        },
      })
      .afterClosed();

  constructor(
    public dialogRef: MatDialogRef<SiteMpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISiteMpDialogData,
    private translateService: TranslateService,
    private diligentAPI: DiligentApiService,
    private dialogService: MatDialog,
    private sitesService: SitesService,
    private notificationsService: NotificationsService,
    private mpService: MeasurementPointsService,
    private geocoderService: GeocoderService,
    private store: Store<fromStore.State>,
    private token: TokenService,
    public changeDetection: ChangeDetectorRef,
    private authService: AuthService
  ) {
    super();
    this.accountName = data.accountName;
    this.accountId = data.accountId;
    this.isAdmin = data.isAdmin;
    this.isSystemAdmin = data.isSystemAdmin;
    this.isPartner = data.isPartner;
    this.currentMpSelected = data.currentMpSelected;
    this.viewerMode = data.viewerMode;
    this.tabSelectionDisabled = data.tabSelectionDisabled;
    this.hideHeader = data.hideHeader;
    this.hideActions = data.hideActions;
    this.defaultEditParameterName = data.defaultEditParameterName;
    if (this.data.mp) {
      this.mp = this.data.mp;
      const siteId = this.data.mp.locationId;
      const measurementPointId = this.data.mp.measurementPointId;
      const siteMp: ISiteMp = {
        site: {
          id: siteId,
          locationName: this.data.mp.locationName,
          shortname: this.data.mp.locationShortname,
          siteInformation: this.data.mp.siteInformation,
          address1: this.data.mp.address1,
          address2: this.data.mp.address2,
          city: this.data.mp.city,
          state: this.data.mp.state,
          zipCode: this.data.mp.zipCode,
          country: this.data.mp.country,
          latitude: this.data.mp.latitude,
          longitude: this.data.mp.longitude,
        },
        measurementPoint: {
          roomId: measurementPointId,
          mpId: this.data.mp.mpId,
          notes: this.data.mp.notes,
          tags: this.data.mp?.tags,
          measurementPointTypeId: this.data.mp.measurementPointTypeId,
          measurementPointStatusId: this.data.mp.measurementPointStatusId,
          commissionedWhen: this.data.mp.commissionedWhen,
          createdWhen: this.data.mp.createdWhen,
          timezone: this.data.mp.timezone,
          mainImageId: this.data.mp.mainImageId,
        },
      };
      this.timezone = siteMp.measurementPoint.timezone;
      let commissionenDate = moment(siteMp.measurementPoint.commissionedWhen).tz(this.timezone);
      this.commissionedDateCtrl = new FormControl(commissionenDate);
      let minDate = moment(siteMp.measurementPoint.createdWhen).tz(this.timezone);
      this.minDate = new Date(minDate.year(), minDate.month(), minDate.date(), minDate.hour(), minDate.minute());

      let maxDate = moment().tz(this.timezone);
      this.maxDate = new Date(maxDate.year(), maxDate.month(), maxDate.date(), maxDate.hour(), maxDate.minute());

      this.siteMp = siteMp;

      if (siteMp.measurementPoint.mainImageId) {
        this.mpImgUrl = `${this.diligentAPI.endpoint}/measurementPoint/${this.siteMp.measurementPoint.roomId}/file/${siteMp.measurementPoint.mainImageId}`;
        this.mpImgId = siteMp.measurementPoint.mainImageId;
        this.mpImgInititalUrl = this.mpImgUrl;
      }

      const status = siteMp.measurementPoint.measurementPointStatusId || 1;
      if (
        ((this.isSystemAdmin && siteMp.measurementPoint.measurementPointTypeId !== 2) ||
          (this.isAdmin &&
            (siteMp.measurementPoint.measurementPointTypeId === 1 ||
              siteMp.measurementPoint.measurementPointTypeId === 3))) &&
        status > 4 &&
        !(this.viewerMode && !this.isSystemAdmin)
      ) {
        this.isMpStatusEnabled = true;

        this.availableStatuses = [];
        if (status === 9) {
          this.availableStatuses.push(status);
          this.availableStatuses.push(1);
        } else {
          if (siteMp.measurementPoint.measurementPointStatusId < 8) {
            this.availableStatuses.push(status);
          }
          this.availableStatuses.push(8);
          if (this.isSystemAdmin || this.isAdmin) {
            this.availableStatuses.push(9);
          }
          this.availableStatuses.push(11);
        }
      }
    } else {
      this.new = !this.tabSelectionDisabled?.includes(0);
    }

    this.title = this.new ? 'manageSiteMp.create' : 'manageSiteMp.edit';

    this.isQubescanLogin = this.token.metaData.isQubescanLogin === 'true';
    this.enableMeridian = moment().creationData().locale.longDateFormat('LT').includes('A');
  }

  ngOnInit(): void {
    const siteName = (this.siteMp && this.siteMp.site.locationName) || '';
    const siteShortName = (this.siteMp && this.siteMp.site.shortname) || '';
    const siteInformation = (this.siteMp && this.siteMp.site.siteInformation) || '';
    const street1 = (this.siteMp && this.siteMp.site.address1) || '';
    const street2 = (this.siteMp && this.siteMp.site.address2) || '';
    const city = (this.siteMp && this.siteMp.site.city) || '';
    const state = (this.siteMp && this.siteMp.site.state) || '';
    const zipCode = (this.siteMp && this.siteMp.site.zipCode) || '';
    const country = (this.siteMp && this.siteMp.site.country) || '';

    this.latitude = ((this.siteMp && this.siteMp.site && this.siteMp.site.latitude) || '') as number;
    this.longitude = ((this.siteMp && this.siteMp.site && this.siteMp.site.longitude) || '') as number;
    const mpName = (this.siteMp && this.siteMp.measurementPoint.mpId) || '';
    const mpTag = (this.siteMp && this.siteMp.measurementPoint.tags) || '';
    const status =
      (this.siteMp && this.siteMp.measurementPoint && this.siteMp.measurementPoint.measurementPointStatusId) || 1;
    let mpTypeId = this.siteMp && this.siteMp.measurementPoint && this.siteMp.measurementPoint.measurementPointTypeId;
    if ((this.new && !this.isPartner) || (this.new && this.isQubescanLogin)) {
      mpTypeId = 1;
    }
    this.statusInitial = status;
    this.siteMpForm = new FormGroup({
      siteName: new FormControl(siteName, [Validators.required]),
      siteShortName: new FormControl(siteShortName, [Validators.required]),
      siteInformation: new FormControl(siteInformation),
      mpTypeId: new FormControl(
        {
          value: mpTypeId,
          disabled: (!this.isPartner && !this.isSystemAdmin && this.new) || !this.new,
        },
        [Validators.required]
      ),
      street1:
        this.latitude && this.longitude ? new FormControl(street1) : new FormControl(street1, [Validators.required]),
      street2: new FormControl(street2),
      city: this.latitude && this.longitude ? new FormControl(city) : new FormControl(city, [Validators.required]),
      state: this.latitude && this.longitude ? new FormControl(state) : new FormControl(state, [Validators.required]),
      zipCode:
        this.latitude && this.longitude ? new FormControl(zipCode) : new FormControl(zipCode, [Validators.required]),
      country:
        this.latitude && this.longitude ? new FormControl(country) : new FormControl(country, [Validators.required]),
      mpName: new FormControl(mpName, [Validators.required, CustomFormValidators.measurementPointNamePattern]),
      gps: this.latitude && this.longitude ? new FormControl(this.formatGpsCoordinate()) : new FormControl(''),
      mpTag: new FormControl(mpTag),
    });

    if (this.isMpStatusEnabled) {
      this.mpStatus = new FormControl(status, [Validators.required]);
    } else {
      this.mpStatus = new FormControl(
        { value: this.translateService.instant(`measurementPoint.status.${status}`), disabled: true },
        [Validators.required]
      );
    }
    this.canModify =
      this.isSystemAdmin ||
      (((this.isPartner && mpTypeId !== 2) ||
        (this.new && !this.isPartner) ||
        (!this.new && !this.isPartner && mpTypeId === 1)) &&
        status !== 9 &&
        !this.viewerMode);

    if (!this.canModify) {
      this.siteMpForm.disable({
        emitEvent: true,
        onlySelf: false,
      });
    }

    if (this.siteMp?.measurementPoint?.roomId) {
      this.mpService
        .getReplacementStep(this.siteMp?.measurementPoint?.roomId)
        .pipe(take(1))
        .subscribe((step) => {
          if (step.length === 0) {
            this.step = null;
          } else {
            this.step = step[0];
          }
        });
    }

    this.store
      .select(fromStore.getUser)
      .pipe(take(1))
      .subscribe((user) => {
        this.userEmail = user.email;
      });

    this.addresses = this.street1.valueChanges.pipe(
      startWith(''),
      switchMap((value) => this.geocoderService.getPlaces(this.userEmail, 'en', '', value))
    );

    this.cityList = this.city.valueChanges.pipe(
      startWith(''),
      switchMap((value) => this.geocoderService.getPlaces(this.userEmail, 'en', '', value))
    );

    this.store
      .pipe(select(fromStore.getUserPreferences))
      .pipe(take(1))
      .subscribe((userPrefs) => {
        this.userPrefs = cloneDeep(userPrefs);

        // Existing or new user who doesn't have the stateMessage in the userPreference
        if (!this.userPrefs.stateMessage)
          this.userPrefs.stateMessage = {
            pause: true,
            archive: true,
          };
      });

    // Check if Access tab is enabled
    this.accessTabEnabled$ = this.diligentAPI
      .getFeature<MeasurementPointAccessFeature>('measurementPointAccess', this.accountId)
      .pipe(
        take(1),
        map((data) => {
          // Feature must be enabled and MP data available (not new MP)
          return data?.enabled === true && !!this.data?.mp?.locationId;
        })
      );
  }

  retryAssociation(): void {
    this.mpService
      .retryAssociation(this.siteMp.measurementPoint.roomId)
      .pipe(take(1))
      .subscribe(() => {
        this.notificationsService.alert(
          this.translateService.instant('manageSiteMp.associate.popupMsg'),
          this.translateService.instant('manageSiteMp.associate.popupTitle')
        );
      });
  }

  openMap(): void {
    const mapDialog = this.dialogService.open(MapDialogComponent, {
      width: '100%',
      height: '100%',
      minWidth: '98vw',
      minHeight: '98vh',
      data: { latLng: this.latitude ? this.formatGpsCoordinate() : '' },
    });

    mapDialog.afterClosed().subscribe((coord) => {
      let temp = coord.split(',');
      this.latitude = parseFloat(temp[0]);
      this.longitude = parseFloat(temp[1].trim());
      this.gps.setValue(coord);
      this.siteMpForm.get('gps').markAsDirty();
      this.getAddress();
      this.siteMpForm.markAllAsTouched();
    });
  }

  getAddress(): void {
    if (this.gps.valid && this.gps.value !== '') {
      let temp = this.gps.value.split(',');
      this.latitude = parseFloat(temp[0]);
      this.longitude = parseFloat(temp[1].trim());

      this.siteMpForm.get('street1').clearValidators();
      this.siteMpForm.get('street1').updateValueAndValidity();
      this.siteMpForm.get('city').clearValidators();
      this.siteMpForm.get('city').updateValueAndValidity();
      this.siteMpForm.get('state').clearValidators();
      this.siteMpForm.get('state').updateValueAndValidity();
      this.siteMpForm.get('zipCode').clearValidators();
      this.siteMpForm.get('zipCode').updateValueAndValidity();
      this.siteMpForm.get('country').clearValidators();
      this.siteMpForm.get('country').updateValueAndValidity();
      this.siteMpForm.get('gps').addValidators(Validators.required);
      this.siteMpForm.get('gps').updateValueAndValidity();
    } else if (this.gps.value === '') {
      if (!this.siteMpForm.get('street1').hasValidator(Validators.required)) {
        this.siteMpForm.get('street1').addValidators(Validators.required);
        this.siteMpForm.get('street1').updateValueAndValidity();
        this.siteMpForm.get('city').addValidators(Validators.required);
        this.siteMpForm.get('city').updateValueAndValidity();
        this.siteMpForm.get('state').addValidators(Validators.required);
        this.siteMpForm.get('state').updateValueAndValidity();
        this.siteMpForm.get('zipCode').addValidators(Validators.required);
        this.siteMpForm.get('zipCode').updateValueAndValidity();
        this.siteMpForm.get('country').addValidators(Validators.required);
        this.siteMpForm.get('country').updateValueAndValidity();
        this.siteMpForm.get('gps').clearValidators();
        this.siteMpForm.get('gps').updateValueAndValidity();
        this.latitude = null;
        this.longitude = null;
      }
    }
  }

  onSelectAddress(placeId: string): void {
    this.geocoderService
      .getPlaceDetails(this.userEmail, 'en', '', placeId, 'address_components,geometry')
      .pipe(take(1))
      .subscribe((results) => {
        if (results.address_components) {
          const address: IAddressComponent[] = results.address_components;
          let streetNumber = '';
          let street = '';
          let city = '';
          let state = '';
          let zip = '';
          let country = '';
          address.forEach((component) => {
            const types = component.types;
            if (types.indexOf('street_number') > -1) {
              streetNumber = component.long_name;
            }
            if (types.indexOf('route') > -1 || types.indexOf('premise') > -1) {
              street = component.long_name;
            }
            if (types.indexOf('locality') > -1) {
              city = component.long_name;
            }
            if (types.indexOf('administrative_area_level_1') > -1) {
              state = component.short_name;
            }
            if (types.indexOf('postal_code') > -1) {
              zip = component.long_name;
            }
            if (types.indexOf('country') > -1) {
              country = component.long_name;
            }
          });
          this.siteMpForm.get('street1').setValue(`${streetNumber}${streetNumber ? ' ' : ''}${street}`);
          if (city) {
            this.siteMpForm.get('city').setValue(city, { emitEvent: false });
          }
          if (state) {
            this.siteMpForm.get('state').setValue(state, { emitEvent: false });
          }
          if (country) {
            this.siteMpForm.get('country').setValue(country, { emitEvent: false });
          }
          if (zip) {
            this.siteMpForm.get('zipCode').setValue(zip, { emitEvent: false });
          }
          // set latitude, longitude and zoom
          this.latitude = results.geometry.location.lat;
          this.longitude = results.geometry.location.lng;
          this.gps.setValue(this.formatGpsCoordinate());
          this.siteMpForm.get('gps').markAsDirty();
        }
      });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private submitMp(parameters: SiteMpParametersTableComponent): void {
    let siteMp: ISiteMp & {
      parameters: MpParameters;
    };
    let measurementPointStatusId =
      (this.siteMp && this.siteMp.measurementPoint && this.siteMp.measurementPoint.measurementPointStatusId) || 1;

    if (this.isMpStatusEnabled) {
      measurementPointStatusId = this.mpStatus.value || 1;
    }

    siteMp = {
      site: {
        locationName: this.siteMpForm.get('siteName').value,
        shortname: this.siteMpForm.get('siteShortName').value,
        address1: this.siteMpForm.get('street1').value,
        address2: this.siteMpForm.get('street2').value || '',
        city: this.siteMpForm.get('city').value,
        state: this.siteMpForm.get('state').value,
        zipCode: this.siteMpForm.get('zipCode').value,
        country: this.siteMpForm.get('country').value,
        siteInformation: this.siteMpForm.get('siteInformation').value || '',
      },
      measurementPoint: {
        mpId: this.siteMpForm.get('mpName').value,
        measurementPointTypeId: this.siteMpForm.get('mpTypeId').value ? this.siteMpForm.get('mpTypeId').value : 2,
        tags: this.siteMpForm.get('mpTag').value,
      },
      parameters: parameters?.parameters,
    };

    if (this.mpImgId) {
      siteMp.measurementPoint.mainImageId = this.mpImgId;
    }

    if (this.latitude !== null && this.longitude !== null) {
      siteMp.site.latitude = this.latitude;
      siteMp.site.longitude = this.longitude;
    }

    if (this.new) {
      this.sitesService
        .createSiteMp(this.accountId, siteMp)
        .pipe(take(1))
        .subscribe({
          next: (response) => {
            if (response) {
              // Refresh the list of MPs
              this.notificationsService.notify(this.translateService.instant('manageSiteMp.save.success'));
              this.dialogRef.close(siteMp);
            } else {
              this.notificationsService.notify(this.translateService.instant('manageSiteMp.save.failed'));
            }
          },
          error: (error) => {
            this.notificationsService.alert(error.error.message, 'Error');
          },
        });
    } else {
      forkJoin([
        this.siteMpForm.dirty || this.mpImgUrl !== this.mpImgInititalUrl
          ? this.sitesService
              .updateSiteMp(this.accountId, this.siteMp.site.id, this.siteMp.measurementPoint.roomId, siteMp)
              .pipe(take(1))
              .pipe(
                map((res) => res),
                catchError((e) => of(e))
              )
          : of(null),
        parameters.valueChanged && siteMp.parameters
          ? this.mpService.saveMpParameters(siteMp.parameters).pipe(
              map((res) => res),
              catchError((e) => of(e))
            )
          : of(null),
        this.commissionedDateCtrl.dirty
          ? this.mpService
              .updateCommissioningDate(
                this.siteMp.measurementPoint.roomId,
                moment(this.commissionedDateCtrl.value).tz(this.timezone, true)
              )
              .pipe(
                map((res) => res),
                catchError((e) => of(e))
              )
          : of(null),
        this.mpStatus.dirty && this.siteMp.measurementPoint.measurementPointStatusId !== measurementPointStatusId
          ? this.sitesService.updateMpStatus(this.siteMp.measurementPoint.roomId, measurementPointStatusId, siteMp)
          : of(null),
        this.submitMpAccess().pipe(
          map((res) => res),
          catchError((e) => of(e))
        ),
      ]).subscribe(([response, responseParameters, commissionedMsg, responseMpStatus, responseMpAccess]) => {
        if (response?.error || responseParameters?.error || responseMpStatus?.error || responseMpAccess?.error) {
          let errorsMsg: string[] = [];
          let errorTitle: string;

          // Create alert message from errors
          if (responseParameters?.error) {
            errorsMsg.push(responseParameters.error.message);
          }
          if (response?.error) {
            errorsMsg.push(response.error.message);
          }
          if (responseMpStatus?.error) {
            errorsMsg.push(responseMpStatus.error.message);
          }
          if (responseMpAccess?.error) {
            errorsMsg.push('Access tab: ' + responseMpAccess.error.message);
          }

          // Create alert tile
          if (responseParameters?.error && response?.error && responseMpStatus?.error) {
            errorTitle = 'Measurement Point and Parameters Error';
          } else if (responseParameters?.error && !response?.error && !responseMpStatus?.error) {
            if (response) {
              errorTitle = 'Measurement Point Edit Successful<br> Parameters Error';
            } else {
              errorTitle = 'Parameters Error';
            }
          } else if (!responseParameters?.error && response?.error && !responseMpStatus?.error) {
            if (responseParameters) {
              errorTitle = 'Measurement Point Error<br> Parameters Edit Successful';
            } else {
              errorTitle = 'Measurement Point Error';
            }
          } else if (responseMpStatus?.error) {
            errorTitle = 'Measurement Point Error';
          } else if (responseMpAccess?.error) {
            if (response) {
              errorTitle = 'Measurement Point Edit Successful<br> Parameters Error';
            } else {
              errorTitle = 'Parameters Error';
            }
          }

          this.notificationsService.alert(errorsMsg.join('<br><br>'), errorTitle);
        } else {
          const msg = [];
          if (response) {
            msg.push(this.translateService.instant('manageSiteMp.save.success'));
            if (this.currentMpSelected) {
              this.store.dispatch(
                UserActions.setHeaderName({
                  payload: {
                    accountName: this.accountName,
                    site: this.siteMpForm.get('siteName').value,
                    mpName: siteMp.measurementPoint.mpId,
                  },
                })
              );
            }
          }
          if (responseParameters) {
            msg.push('Parameters updated sucessfuly');
          }
          if (commissionedMsg) {
            msg.push(commissionedMsg.message);
          }
          if (responseMpStatus) {
            this.refreshMpStatus.emit(measurementPointStatusId);
            if (this.currentMpSelected) {
              this.mp = cloneDeep(this.mp);
              this.mp.measurementPointStatusId = measurementPointStatusId;
            }
            this.statusInitial = this.mpStatus.value;
            msg.push('Measurement Point status updated successfully');
          }
          if (msg.length > 0) {
            if (this.currentMpSelected) {
              this.mpService
                .getMeasurementPoint(this.siteMp.measurementPoint.roomId)
                .pipe(take(1))
                .subscribe((mp) => {
                  mp.measurementPointId = mp.roomId;
                  this.store.dispatch(UserActions.setMpStatusSuccess({ payload: { mp: mp } }));
                });
            }
            this.notificationsService.notify(msg.join(' and '));
            this.dialogRef.close(siteMp);
          }
        }
      });
    }
  }

  onSubmit(event: Event, parameters: SiteMpParametersTableComponent): void {
    if (
      this.siteMpForm.invalid &&
      this.tabgroup.selectedIndex === this.getIndexFromTabLabel(this.translateService.instant('site.tabName.location'))
    ) {
      this.siteMpForm.markAllAsTouched();
      return;
    }

    if (this.mpStatus.value === 9 && this.statusInitial !== this.mpStatus.value) {
      this.notificationsService
        .confirm(this.translateService.instant('manageSiteMp.stateChange.saveArchive'))
        .afterClosed()
        .pipe(filter((valid) => valid))
        .subscribe(() => {
          this.submitMp(parameters);
        });
    } else {
      this.submitMp(parameters);
    }
  }

  deleteMp(event): void {
    event.stopPropagation();
    const deleteMemberDialog = this.dialogService.open(DeleteMemberDialogComponent, {
      width: '450px',
      disableClose: false,
      data: {
        mp: this.mp,
        accountName: this.accountName,
      },
      autoFocus: false,
      restoreFocus: false,
    });

    deleteMemberDialog.afterClosed().subscribe((confirmDelete: boolean) => {
      if (!confirmDelete) {
        return;
      }

      this.mpService
        .deleteMp(this.mp.measurementPointId)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.notificationsService.notify(this.translateService.instant('manageSiteMp.delete.success'));
            this.dialogRef.close(this.siteMp);
          },
          error: (error) => {
            if (error?.error?.message) {
              this.notificationsService.notify(this.translateService.instant('manageSiteMp.delete.failed'));
            } else {
              this.notificationsService.notify(error.error.message);
            }
          },
        });
    });
  }

  unassociateMp(event): void {
    event.stopPropagation();
    const deleteMemberDialog = this.dialogService.open(DeleteMemberDialogComponent, {
      width: '500px',
      disableClose: false,
      data: {
        mp: this.mp,
        accountName: this.accountName,
        unassociate: true,
      },
      autoFocus: false,
      restoreFocus: false,
    });

    deleteMemberDialog.afterClosed().subscribe((confirmDelete: boolean) => {
      if (!confirmDelete) {
        return;
      }

      this.mpService
        .disassociateDevice(this.mp.measurementPointId)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.notificationsService.notify(this.translateService.instant('manageSiteMp.unassociate.success'));
            this.dialogRef.close(this.siteMp);
          },
          error: (error) => {
            if (error?.error?.message) {
              this.notificationsService.notify(this.translateService.instant('manageSiteMp.unassociate.failed'));
            } else {
              this.notificationsService.notify(error.error.message);
            }
          },
        });
    });
  }

  disassociateEvent(): void {
    this.mpService
      .disassociateDevice(this.mp.measurementPointId)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.refreshMpStatus.emit();
          this.availableStatuses.push(1);
          this.notificationsService.notify(this.translateService.instant('manageSiteMp.unassociate.success'));
          this.mpStatus.patchValue(1);
          this.mpStatus.disable();
          this.siteMp.measurementPoint.measurementPointStatusId = 1;
          this.changeDetection.detectChanges();
        },
        error: (error) => {
          if (error?.error?.message) {
            this.notificationsService.notify(this.translateService.instant('manageSiteMp.unassociate.failed'));
          } else {
            this.notificationsService.notify(error.error.message);
          }
        },
      });
  }

  associateEvent(): void {
    this.refreshMpStatus.emit();
    this.availableStatuses.push(4);
    this.mpStatus.patchValue(4);
    this.siteMp.measurementPoint.measurementPointStatusId = 4;
    this.changeDetection.detectChanges();
  }

  updateStep(step: ReplacementStep): void {
    this.step = step;
    this.changeDetection.detectChanges();
  }

  fileDownload(event): void {
    if (event && event.length > 0) {
      const index = event.length - 1;
      if (event[index].type?.split('/')[0] === 'image') {
        this.imgReady = false;
        let imgForm = new FormData();
        imgForm.append('file', event[index]);

        this.diligentAPI
          .addMeasurementPointFile(this.siteMp.measurementPoint.roomId, imgForm)
          .pipe(take(1))
          .subscribe((result) => {
            this.mpImgUrl = `${this.diligentAPI.endpoint}/measurementPoint/${this.siteMp.measurementPoint.roomId}/file/${result.id}`;
            this.mpImgId = result.id;
            this.imgReady = true;
            this.changeDetection.detectChanges();
          });
      } else {
        this.notificationsService.notify(
          this.translateService.instant('manageSiteMp.image.unsupportedFile', {
            fileName: event[index].name ? event[index].name : 'unknown',
          })
        );
      }
    }
  }

  showStateHint(value: number, savePref: boolean = true): void {
    this.notificationsService.alert(
      this.translateService.instant(`manageSiteMp.stateChange.${value}`),
      this.translateService.instant('manageSiteMp.stateChange.title')
    );

    if (savePref) {
      this.authService.savePreferences(cloneDeep(this.userPrefs), true);
    }
  }

  statusChange(value: number): void {
    if (value === 9 && this.userPrefs.stateMessage?.archive) {
      this.userPrefs.stateMessage.archive = false;
      this.showStateHint(value);
    } else if (value === 11 && this.userPrefs.stateMessage?.pause) {
      this.userPrefs.stateMessage.pause = false;
      this.showStateHint(value);
    }
  }

  removeMainImage(): void {
    this.siteMp.measurementPoint.mainImageId = null;
    this.mpImgUrl = null;
    this.mpImgId = null;
    this.changeDetection.detectChanges();
    this.dragDropImage.removeFiles();
  }

  tabSelection(event: MatTabChangeEvent): void {
    if (
      this.tabgroup.selectedIndex ===
        this.getIndexFromTabLabel(this.translateService.instant('site.tabName.location')) &&
      this.siteMpForm.invalid &&
      this.siteMpForm.dirty
    ) {
      this.siteMpForm.markAllAsTouched();
    } else if (event.tab.textLabel === this.translateService.instant('site.tabName.moveMP')) {
      this.moveToTab.init();
    }
  }

  formatGpsCoordinate(): string {
    return this.latitude + ', ' + this.longitude;
  }

  getIndexFromTabLabel(tabLabel: string): number {
    return this.tabgroup?._tabs.toArray().findIndex((tab) => tab.textLabel === tabLabel);
  }

  siteEnterEvent(): void {
    this.getAddress();
    if (
      this.siteMpForm.invalid &&
      this.tabgroup.selectedIndex === this.getIndexFromTabLabel(this.translateService.instant('site.tabName.location'))
    ) {
      this.siteMpForm.markAllAsTouched();
    }
  }

  /**
   * Post to BE API Mp Access changes
   * generated on mp-access.component
   */
  submitMpAccess(): Observable<any> {
    if (Object.keys(this.accessChanges).length > 0) {
      const payload = Object.entries(this.accessChanges).map(([userId, access]) => ({
        userId: Number(userId),
        access: access,
      }));
      return this.diligentAPI.putMpUserAccess(this.siteMp.measurementPoint.roomId, payload);
    }
    return of(null);
  }

  /**
   * Receive changes from mp-access.component
   * @param accessChanges
   */
  receiveAccessChange(accessChanges: { [key: string]: boolean }): void {
    this.accessChanges = accessChanges;
    this.siteMpForm.markAsDirty();
  }
}
