import { Action } from '@ngrx/store';

import { HierarchyActions } from 'src/app/_store/_hierarchy/actions';
import { Hierarchy } from 'src/app/_shared/interface/hierarchy.interface';

export interface State {
  hierarchy: Hierarchy;
  filteredHierarchy: Hierarchy;
}

const initialState: State = {
  hierarchy: { partners: [] },
  filteredHierarchy: { partners: [] },
};

export function reducer(state: State = initialState, action: Action): State {
  const specificAction = action as HierarchyActions.HierarchyActionsUnion;

  switch (specificAction.type) {
    case HierarchyActions.getHierarchySuccess.type:
      return {
        ...state,
        hierarchy: specificAction.hierarchy,
      };
    case HierarchyActions.getFilterHierarchySuccess.type:
      return {
        ...state,
        filteredHierarchy: specificAction.hierarchy,
      };

    default:
      return state;
  }
}

export const getHierarchy = (state: State): State['hierarchy'] => state.hierarchy;
export const getPartners = (state: State): Hierarchy['partners'] => state.hierarchy.partners;
export const getFilterPartners = (state: State): Hierarchy['partners'] => state.filteredHierarchy.partners;
